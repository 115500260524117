<template>
  <div class="merch-box">
    <div class="title">
      商品图片<span class="hint" v-if="$route.query.steured=='false'"
        >（*温馨提示：当前信息为商品关键属性，请仔细核对确保无误）</span
      >
    </div>
    <div class="center-box">
      <!-- 商品图片 -->
      <!-- 修改前 -->
      <div>
        <div class="goods-img-box">
          <div class="img-title">修改前</div>
          <div class="goods-img" v-if="AuditbusinessData.Modifyform">
            <div v-if="AuditbusinessData.Modifyform.picture.length > 0">
              <div
                v-for="item in AuditbusinessData.Modifyform.picture"
                :key="item.url"
                style="display: inline-block"
                class="rela marle_10"
              >
                <el-image
                  style="width: 100px; height: 100px; border-radius: 10px"
                  :src="item.url"
                  :preview-src-list="[item.url]"
                >
                </el-image>
              </div>
            </div>
            <div class="exceed_color font-size-14" v-else>未上传商品图片</div>
          </div>
        </div>
        <!-- 修改后 -->
        <div class="goods-img-box">
          <div class="img-title">修改后</div>
          <div class="goods-img" v-if="AuditbusinessData.picture">
            <div
              v-for="item in AuditbusinessData.picture"
              :key="item.url"
              style="display: inline-block"
              class="rela marle_10"
            >
              <el-image
                style="width: 100px; height: 100px; border-radius: 10px"
                :src="item.url"
                :preview-src-list="[item.url]"
              >
              </el-image>
              <!-- <div class="absoimg" style="width: 100px">点击放大</div> -->
            </div>
          </div>
        </div>
      </div>

      <!-- 修改后 -->
      <div class="goods-img-box">
        <div class="img-title">修改原因</div>
        <div class="cause">
          {{ AuditbusinessData.modification }}
          <!-- <el-input
            disabled
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="AuditbusinessData.modification"
          >
          </el-input> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
};
</script>

<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 16px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    position: relative;
    padding-left: 20px;
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
    border-radius: 4px;
    display: flex;
    > div {
      flex: 1;
    }
    .goods-img-box {
      width: 100%;
      .img-title {
        line-height: 30px;
      }
      .el-image {
        margin-right: 5px;
      }
      .goods-img {
        padding-bottom: 10px;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 10px;
      }
      .cause {
        font-size: 14px;
      }
    }
  }
}
.hint {
  color: #f00;
  font-size: 12px;
  font-weight: normal;
}
</style>
