<template>
  <div class="merch-box">
    <div class="title">商品名称</div>
    <div
      class="center-box"
      v-if="AuditbusinessData.Modifyform || AuditbusinessData.basicInfo"
    >
    <div>商品名称（修改前） <div>{{ AuditbusinessData.Modifyform.basicInfo.goodsName }}</div></div>
    <div>商品名称（修改后） <div class="new-goodsname"> {{ AuditbusinessData.basicInfo.goodsName}}</div></div>
    <div>修改原因 <div>{{ AuditbusinessData.basicInfo.modification }}</div></div>
    
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
};
</script>
<style lang='scss' scoped>
.el-input {
  /* width: 400px; */
}
.el-textarea__inner {
  /* width: 400px !important; */
} 
</style>
<style lang="scss" scoped>
.merch-box {
  padding: 10px;
  width: 100%;
   .title {
    width: 100%;
    font-weight: bold;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .center-box {
    position: relative;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 20px;
    margin-top: 10px;
    width: 100%;
    margin-bottom: 10px;
    // border: 1px solid #797979;
    border-radius: 4px;
    display: flex;
    font-size: 14px;
    >div{
      flex: 1;
      display: flex;
      align-items: center;
      color: #666666;
      .new-goodsname{
        color: #06B7AE;
      }
      >div{
        margin-left: 50px;
        color: #333;
      }
    }
  }
}
</style>
