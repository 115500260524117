<template>
  <div>
    <div class="message-box">
      <div class="title-box">
        <div class="title">商品基本信息</div>
        <el-link class="goDetails" :underline="false" @click="goDetails"
          >查看商品详情</el-link
        >
      </div>
      <div class="message-box-box">
        <el-descriptions :column="1">
          <el-descriptions-item
            :label="item.name"
            v-for="(item, index) in basicInfo"
            :key="index"
          >
            <span v-if="item.key == 'goodsType'">
              <span v-if="basicinfoData[item.key] == 0">普药</span>
              <span v-if="basicinfoData[item.key] == 1">中药</span>
              <span v-if="basicinfoData[item.key] == 2">非药品</span>
            </span>
            <span v-else-if="item.key == 'type'">
              <span v-if="basicinfoData[item.key] == 0">普药</span>
              <span v-if="basicinfoData[item.key] == 1">中药</span>
              <span v-if="basicinfoData[item.key] == 2">非药品</span>
            </span>
            <div v-else-if="item.key == 'categoryArr'">
              <!-- {{basicinfoData.categoryArr}} -->
              <div v-for="(v, i) in basicinfoData.categoryArr" :key="i">
                {{ v.categoryName }}
                <span v-if="v.children">
                  > {{ v.children[0].categoryName }}</span
                >
                <span v-if="v.children[0].children">
                  > {{ v.children[0].children[0].categoryName }}</span
                >
              </div>
            </div>
            <span v-else> {{ basicinfoData[item.key] || "/" }}</span>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    basicInfo: {
      type: Array,
      default: () => {
        return [];
      },
    },
    basicinfoData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      srcList: [
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
    };
  },
  methods: {
    goDetails() {
      let id = "";
      if (this.$route.query.platform_goods_info_id) {
        id = this.$route.query.platform_goods_info_id;
      }
      if (this.$route.query.type == 3) {
        id = this.$route.query.id;
      }
      this.$router.push({
        path: "/cloudGoodMessage",
        query: {
          id: id,
        },
      }); //编辑
    },
  },
};
</script>

<style lang="scss" scoped>
.message-box {
  padding: 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // .title {
  //   width: 120px;
  //   margin-top: 10px;
  //   height: 30px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   background: #00bfbf;
  //   color: #fff;
  //   border-radius: 4px;
  // }
  .title-box {
    width: 100%;
    border-bottom: dashed 1px #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
  }
  .title {
    width: 100%;
    font-weight: bold;
    span {
      color: #f00;
      font-size: 12px;
      font-weight: normal;
    }
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
  .goDetails {
    // height: 30px;
    // margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    color: #00bfbf;
    width: 100px;
  }
  .message-box-box {
    margin-top: 10px;
    width: 100%;
    // border: 1px solid #797979;
  }
}
::v-deep .el-descriptions__table {
  display: grid;
  grid-template-columns: repeat(3, 33.3%);
  font-size: 14px !important;
  color: #333;
}
::v-deep .el-descriptions-item__label {
  width: 60px !important;
  color: #666;
  margin-right: 20px !important;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
</style>
