<template>
  <div class="MERCH redactGood">
    <div class="merch-card">
      <div class="message-box scorr-roll">
        <!-- 未通过显示框 -->
        <template v-if="AuditbusinessData.auditInfo">
          <div
            v-if="
              AuditbusinessData.auditInfo.status == 3 ||
              AuditbusinessData.auditInfo.status == 2
            "
          >
            <NotPassGood :UserQualification="AuditbusinessData.auditInfo" />
          </div>
        </template>

        <!-- 供应商 -->
        <div>
          <MerchantGood
            :businessInfo="goodsRedact.businessInfo"
            :businessInfoNew="AuditbusinessData.business"
          />
        </div>
        <!-- 基本信息 -->
        <!-- <div>
          <MessageGood
            :basicInfo="goodsRedact.basicInfo"
            :basicinfoData="AuditbusinessData.basicInfo"
          />
        </div> -->
        <!-- 修改图片 -->
        <div v-if="$route.query.type == 3">
          <RevampImgGood />
        </div>
        <!-- 修改名称 -->
        <div v-else-if="$route.query.type == 4">
          <RevampnameGood />
        </div>
        <div>
          <BasicMessageGood
            :basicInfo="goodsRedact.basicInfo"
            :picture="goodsMessage.picture"
            :manufacturerInfo="goodsMessage.manufacturerInfo"
            :label="goodsMessage.label"
            :basicinfoData="AuditbusinessData.basicInfo"
            :imgDate="AuditbusinessData.picture"
            :manuData="AuditbusinessData.manufacturerInfo"
            :labData="AuditbusinessData.label"
            :chagepic="$route.query.type == 3"
          />
        </div>
        <!-- 商品说明书 -->
        <!-- <div>
          <ExplainGood
            :instruction="goodsMessage.instruction"
            :instDate="AuditbusinessData.instruction"
          />
        </div> -->
        <!--   -->
        <!-- 价格/库存 -->
        <div>
          <PriceGood
            :priceAndInventory="goodsMessage.priceAndInventory"
            :priceData="AuditbusinessData.priceAndInventory"
          />
        </div>
        <!-- 销售设置 -->
        <div>
          <SellSetGood
            :saleSetting="goodsMessage.saleSetting"
            :saleData="AuditbusinessData.saleSetting"
          />
        </div>
        <!-- 产品资质 -->
        <div>
          <QualificationGood
            :qualify="goodsMessage.qualify"
            :qualifyData="AuditbusinessData.qualify"
          />
        </div>
      </div>
      <template v-if="AuditbusinessData.auditInfo">
        <div
          class="btn-box"
          v-if="
            AuditbusinessData.auditInfo.status != 3 &&
            AuditbusinessData.auditInfo.status != 2
          "
        >
          <div class="go-back" @click="toBack">返回</div>
          <div class="refuse" @click="toRef">审核驳回</div>
          <div class="pass" @click="ispass = true">审核通过</div>
        </div>
        <div class="btn-box" v-else>
          <div class="go-back" @click="toBack">返回</div>
        </div>
      </template>
    </div>

    <!-- 确认弹框 -->
    <div>
      <el-dialog title="审核通过提醒" :visible.sync="ispass" width="400px">
        <div class="explain">
          确定通过当前【<span v-if="$route.query.type == 3">修改商品图片</span
          ><span v-if="$route.query.type == 4">修改商品名称</span
          >】审核？请您仔细核对商品信息，以保证当前商品资质信息均符合要求且填写无误
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="ispass = false">取 消</el-button>
          <el-button type="primary" @click="allowPass">审核通过</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 拒绝弹框 -->
    <div>
      <el-dialog title="审核驳回提醒" :visible.sync="isRefuse" width="400px">
        <div class="explain">
          确定驳回当前【<span v-if="$route.query.type == 3">修改商品图片</span
          ><span v-if="$route.query.type == 4">修改商品名称</span
          >】审核？如果当前商品资质或信息不符合平台要求，请在驳回原因中注明
        </div>
        <div class="reason">
          <span>驳回原因：</span>

          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="AuditMessage.remark"
            maxlength="200"
            show-word-limit
          >
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="isRefuse = false">取 消</el-button>
          <el-button type="primary" @click="notarizeRefuse">驳 回</el-button>
        </span>
      </el-dialog>
    </div>
    <el-dialog title="" :visible.sync="replacePicture" width="750px">
      <div class="text1" style="margin-bottom: 10px">是否替换资料库信息?</div>
      <div class="text1 text-color">
        替换资料库信息会影响所有使用资料库信息的供应商，请谨慎操作
      </div>
      <div class="goods-pop">
        <div class="goods-info">
          <div class="goods-xin">
            <div class="text11">名称：{{ goodsInfoForm.goodsName }}</div>
            <div class="text11">规格：{{ goodsInfoForm.specification }}</div>
            <div class="text11">厂家：{{ goodsInfoForm.manufacturer }}</div>
            <div class="text11">
              批准文号：{{ goodsInfoForm.approvalNumber }}
            </div>
            <div style="text-align: left">
              <el-image
                v-for="(v, i) in goodsInfoForm.picture"
                :key="i"
                class="goods-img"
                :src="v"
                :preview-src-list="goodsInfoForm.picture"
              ></el-image>
            </div>

            <div class="text11">供应商上传资料</div>
          </div>
          <div class="goods-pop" style="margin-top: 20px">
            <div style="margin-right: 20px">是否OTC</div>
            <el-radio-group v-model="isOtc">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
          <div class="goods-pop" style="margin-top: 20px">
            <div style="margin-right: 20px">是否采集</div>
            <el-radio-group v-model="isCollect">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="jian-tou">
          <div class="text-color" style="margin-bottom: 20px">
            替换资料库信息
          </div>
          <img
            style="width: 50px; height: 50px"
            src="../../../assets/img/img-rigth.png"
          />
        </div>
        <div class="goods-info">
          <div class="goods-xin">
            <div class="text11">名称：{{ goodsInfoForm.goodsName }}</div>
            <div class="text11">规格：{{ goodsInfoForm.specification }}</div>
            <div class="text11">厂家：{{ goodsInfoForm.manufacturer }}</div>
            <div class="text11">
              批准文号：{{ goodsInfoForm.approvalNumber }}
            </div>
            <div style="text-align: left">
              <el-image
                v-for="(v, i) in goodsInfoForm.platform_picture"
                :key="i"
                class="goods-img"
                :src="v"
                :preview-src-list="goodsInfoForm.platform_picture"
              ></el-image>
            </div>
            <div class="text11">资料库资料</div>
          </div>
        </div>
      </div>
      <div class="sure-btn">
        <el-button
          style="margin-right: 100px"
          v-loading="btnLoading"
          @click="nextPass(false)"
          >不替换</el-button
        >
        <el-button type="primary" @click="nextPass(true)">确认替换</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BasicMessageGood from "@/components/ordinaryGoods/basicMessage.vue"; //基本信息
import MerchantGood from "@/components/ordinaryGoods/merchant.vue"; //供应商
import MessageGood from "../../../components/ordinaryGoods/message.vue"; //基本信息
import ExplainGood from "@/components/ordinaryGoods/explain.vue"; //商品说明书
import SellSetGood from "@/components/ordinaryGoods/sellSet.vue"; //销售设置
import PriceGood from "@/components/ordinaryGoods/price.vue"; //价格/库存
import QualificationGood from "@/components/ordinaryGoods/qualification.vue"; //产品资质
import NotPassGood from "./comp/notPass.vue"; //产品资质
import RevampImgGood from "@/components/ordinaryGoods/revampImg.vue"; //产品资质
import RevampnameGood from "@/components/ordinaryGoods/revampname.vue"; //产品资质
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      isCollect: 0,
      isOtc: 0,
      goodsInfoForm: {},
      btnLoading: false,
      goodsMessage: require("@/assets/json/cloudGood.json"),
      activeName: 0,
      goodsUrl:
        "https://image.hezongyy.com/images/202210/source_img/goods_64783_18170002.jpg",
      replacePicture: false,
      ispass: false,
      isRefuse: false,
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
      },
      goodsPictureFrom: {},
      goodsRedact: require("@/assets/json/goodRedact.json"),
    };
  },
  components: {
    MerchantGood, //供应商
    MessageGood, //基本信息
    ExplainGood, //商品说明书
    SellSetGood, //销售设置
    PriceGood, //价格/库存
    QualificationGood, //产品资质
    NotPassGood, //未通过
    RevampImgGood, //修改图片
    RevampnameGood, //修改名称
    BasicMessageGood,
  },
  created() {
    if (this.$route.query.id) {
      this.postgoodsAuditbusinessDetail({
        id: this.$route.query.id,
      });
    }
  },
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
  methods: {
    ...mapActions([
      "postgoodsAuditbusinessDetail",
      "postgoodsAuditeditMerchant",
    ]),
    allowPass() {
      if (this.$route.query.type == 3) {
        Object.assign(
          this.goodsInfoForm,
          this.AuditbusinessData.basicInfo,
          this.AuditbusinessData.replacePicture
        );
        this.replacePicture = true;
      } else {
        this.nextPass(false);
      }
    },
    // 通过
    async nextPass(isReplace) {
      this.AuditMessage = {
        id: this.$route.query.id,
        status: 2,
        name: this.AuditbusinessData.basicInfo.goodsName,
        goods_info_id: this.$route.query.platform_goods_info_id,
        picture_url: this.AuditbusinessData.picture,
        is_replace: 0,
      };
      if (this.$route.query.type == 3 && isReplace) {
        this.AuditMessage.is_collect = this.isCollect;
        this.AuditMessage.is_otc = this.isOtc;
        this.AuditMessage.is_replace = 1;
      }
      let data = await this.postgoodsAuditeditMerchant(this.AuditMessage);
      if (data.code == 200) {
        this.isRefuse = false;
        this.replacePicture = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    toRef() {
      this.AuditMessage = {
        id: this.$route.query.id,
      };
      this.isRefuse = true;
    },
    // 返回列表
    toBack() {
      this.$router.push({ path: "/goodsCheck" });
    },
    // 拒绝
    async notarizeRefuse() {
      this.AuditMessage.status = 3;
      if (!this.AuditMessage.remark || this.AuditMessage == "") {
        this.$message({
          message: "您还没有填写驳回原因，请填写后重试。",
          type: "warning",
        });
        return;
      }
      let data = await this.postgoodsAuditeditMerchant(this.AuditMessage);
      if (data.code == 200) {
        this.isRefuse = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    toMessage() {
      this.$router.push({ path: "/cloudGoodMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
    }
  }
  .text1 {
    text-align: center;
    font-size: 16px;
  }
  .text-color {
    color: red;
  }
  .goods-pop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .jian-tou {
    margin: 0px 20px;
    text-align: center;
  }
  .goods-info {
    width: 40%;
    margin-top: 30px;
    text-align: center;
    .goods-xin {
      border: 1px solid #1c1f23;
      border-radius: 8px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text11 {
        margin: 10px 0px;
        font-size: 15px;
      }
      .goods-img {
        width: 100px;
        height: 100px;
        margin: 5px;
      }
    }
  }
  .sure-btn {
    margin-top: 50px;
    text-align: center;
  }
  .btn-box {
    cursor: pointer;
    margin-top: 1%;
    width: 100%;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
      line-height: 32px;
    }
    .go-back {
      width: 88px;
      color: rgba($color: #1c1f23, $alpha: 0.6);
      border: 1px solid #d4d7d9;
    }
    .refuse {
      width: 116px;
      background: #f6b142;
      color: #fff;
      margin: 0 20px;
    }
    .pass {
      width: 116px;
      background: #00bfbf;
      color: #ffffff;
    }
  }
}
.explain {
  line-height: 24px;
}
.reason {
  display: flex;
  line-height: 24px;
  margin-top: 10px;
  span {
    width: 100px;
  }
}
::v-deep .el-dialog__body {
  padding: 20px;
  padding-top: 0;
}
</style>
